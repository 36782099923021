<div class="ch-login">
  <div class="ch-login-container">
    <div class="ch-login-header">

      <img class="logo" src="assets/images/logo-blue.png">

      <h1 class="ui header">
        <span class="ui sub header">
          Recuperar Senha
        </span>
      </h1>
    </div>
    <div class="ch-login-content">
      <div class="ch-login-form">

        <div *ngIf="result.error">
          <div class="ui error message">
            <div class="header">
              Atenção!
            </div>
            <p>Erro ao Salvar senha!</p>
          </div>
        </div>

        <div *ngIf="!result.error">

          <form class="ui form" [formGroup]="form" *ngIf="!result.success">

            <div class="field required" [ngClass]="{'error': form.controls.newPassword.invalid && submited}">
              <label>
               Nova senha
              </label>
              <div class="ui action input">
                <input tabindex="2" [type]="show.newPassword ? 'text' : 'password'" required
                       formControlName="newPassword">
                <button tabindex="-1" class="ui icon blue button" (click)="show.newPassword = !show.newPassword">
                  <i class="fas fa-icon"
                     [ngClass]="{'fa-eye-slash': show.newPassword, 'fa-eye': !show.newPassword}"></i>
                </button>
              </div>
              <ch-pointing-label *ngIf="submited && form.controls.newPassword?.errors?.required">
                Senha é obrigatória
              </ch-pointing-label>
            </div>

            <div class="field required"
                 [ngClass]="{'error': form.controls.newPasswordConfirmation.invalid && submited}">
              <label>
                Confirmar nova senha
              </label>
              <div class="ui action input">
                <input tabindex="3" [type]="show.newPasswordConfirmation ? 'text' : 'password'" required
                       formControlName="newPasswordConfirmation">
                <button tabindex="-1" class="ui icon blue button"
                        (click)="show.newPasswordConfirmation = !show.newPasswordConfirmation">
                  <i class="fas fa-icon"
                     [ngClass]="{'fa-eye-slash': show.newPasswordConfirmation, 'fa-eye': !show.newPasswordConfirmation}"></i>
                </button>
              </div>

              <ch-pointing-label *ngIf="submited && form.controls.newPasswordConfirmation?.errors?.required">
               Confirmação de senha é obrigatoria
              </ch-pointing-label>
              <ch-pointing-label *ngIf="submited && form.controls.newPasswordConfirmation?.errors?.match">
                Senhas não coincidence
              </ch-pointing-label>
            </div>



            <div class="ui divider hidden"></div>
            <button class="ui fluid button" (click)="save()">
              Salvar
              <i class="fas fa-save fa-icon"></i>
            </button>
          </form>

          <div *ngIf="result.success">
            <div class="ui green message">
              <i class="close icon" [routerLink]="['/login']"></i>
              <div class="header">
                Senha Alterada com sucesso!
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="ch-login-footer">

    </div>
  </div>
</div>
