<div ch-grid-row>

  <div ch-grid-column tablet="16" computer="8">
    <form class="ui form" [formGroup]="form">

      <div class="field required" [ngClass]="{'error': form.controls.currentPassword.invalid && submited}">
        <label>
          Senha Atual
        </label>

        <div class="ui action input">
          <input tabindex="1" [type]="show.currentPassword ? 'text' : 'password'" required formControlName="currentPassword">
          <button tabindex="-1" class="ui icon blue button" (click)="show.currentPassword = !show.currentPassword">
            <i class="fas fa-icon" [ngClass]="{'fa-eye-slash': show.currentPassword, 'fa-eye': !show.currentPassword}"></i>
          </button>
        </div>


        <ch-pointing-label *ngIf="submited && form.controls.currentPassword?.errors?.required">
          A senha Atual é Obrigatória
        </ch-pointing-label>
      </div>

      <div class="field required" [ngClass]="{'error': form.controls.newPassword.invalid && submited}">
        <label>
          Nova Senha
        </label>
        <div class="ui action input">
          <input tabindex="2" [type]="show.newPassword ? 'text' : 'password'" required formControlName="newPassword">
          <button tabindex="-1" class="ui icon blue button" (click)="show.newPassword = !show.newPassword">
            <i class="fas fa-icon" [ngClass]="{'fa-eye-slash': show.newPassword, 'fa-eye': !show.newPassword}"></i>
          </button>
        </div>
        <ch-pointing-label *ngIf="submited && form.controls.newPassword?.errors?.required">
          Nova Senha é Obrigatória
        </ch-pointing-label>
      </div>

      <div class="field required" [ngClass]="{'error': form.controls.newPasswordConfirmation.invalid && submited}">
        <label>
          Confirmar nova senha
        </label>
        <div class="ui action input">
          <input tabindex="3" [type]="show.newPasswordConfirmation ? 'text' : 'password'" required formControlName="newPasswordConfirmation">
          <button tabindex="-1" class="ui icon blue button" (click)="show.newPasswordConfirmation = !show.newPasswordConfirmation">
            <i class="fas fa-icon" [ngClass]="{'fa-eye-slash': show.newPasswordConfirmation, 'fa-eye': !show.newPasswordConfirmation}"></i>
          </button>
        </div>

        <ch-pointing-label *ngIf="submited && form.controls.newPasswordConfirmation?.errors?.required">
          Confirmação de senha é obrigatoria
        </ch-pointing-label>
        <ch-pointing-label *ngIf="submited && form.controls.newPasswordConfirmation?.errors?.match">
          Senhas não coincidence
        </ch-pointing-label>
      </div>

      <div class="field">
        <button tabindex="-1" class="ui small button m-r-10" (click)="goBack()">
          Cancelar
          <i class="fal fa-ban fa-icon"></i>
        </button>
        <button tabindex="4" class="ui blue small button m-r-5" (click)="save()">
          Salvar
          <i class="fal fa-save fa-icon"></i>
        </button>
      </div>
    </form>

  </div>
</div>
